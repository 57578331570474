{
  "name": "bo-app",
  "version": "2.4.3-f426d019.0",
  "scripts": {
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.2",
    "@angular/cdk": "^19.2.3",
    "@angular/common": "^19.2.2",
    "@angular/compiler": "^19.2.2",
    "@angular/core": "^19.2.2",
    "@angular/forms": "^19.2.2",
    "@angular/material": "^19.2.3",
    "@angular/platform-browser": "^19.2.2",
    "@angular/platform-browser-dynamic": "^19.2.2",
    "@angular/router": "^19.2.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.4.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.2",
    "@angular/cli": "^19.2.2",
    "@angular/compiler-cli": "^19.2.2",
    "@angular/material-luxon-adapter": "^19.2.3",
    "@sparbanken-syd/borgo-helpers": "^1.3.1",
    "@sparbanken-syd/borgo-types": "^1.0.1",
    "@sparbanken-syd/personnummer": "^3.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.3",
    "@types/jasmine": "^5.1.7",
    "@types/jmespath": "^0",
    "@types/karma-spec-reporter": "^0",
    "@types/luxon": "^3.4.2",
    "@types/node": "22",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "@typescript-eslint/parser": "^8.26.1",
    "@typescript-eslint/types": "^8.26.1",
    "@typescript-eslint/utils": "^8.26.1",
    "angular-eslint": "^19.2.1",
    "eslint": "^9.22.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsdoc": "^50.6.6",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "^5.6.0",
    "jmespath": "^0.16.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "^0.0.36",
    "luxon": "^3.5.0",
    "postcss": "^8.5.3",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.15.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.11.1",
    "typescript": "~5.8.2",
    "typescript-eslint": "^8.26.1"
  },
  "closed": {
    "start": "2025-04-01T06:00:00",
    "end": "2025-04-01T08:00:00"
  }
}
